// Import everything from autoload folder
import './autoload/ada-compliance.js'; import './autoload/foundation.js'; // eslint-disable-line

// Import local dependencies
import './plugins/lazyload';
import './plugins/modernizr.min';
import 'slick-carousel';
import 'jquery-match-height';
import objectFitImages from 'object-fit-images';
// import '@fancyapps/fancybox/dist/jquery.fancybox.min';
// import { jarallax, jarallaxElement } from 'jarallax';
// import ScrollOut from 'scroll-out';

/**
 * Import scripts from Custom Divi blocks
 */
// eslint-disable-next-line import/no-unresolved
//import '../blocks/divi/example-block/index.js';

/**
 * Import scripts from Custom Elementor widgets
 */
// eslint-disable-next-line import/no-unresolved
//import '../blocks/elementor/example-widget/index.js';

/**
 * Import scripts from Custom ACF Gutenberg blocks
 */
// eslint-disable-next-line import/no-unresolved
//import '../blocks/gutenberg/acf-example-block/index.js';

/**
 * Init foundation
 */
$(document).foundation();

/**
 * Fit slide video background to video holder
 */
function resizeVideo() {
  let $holder = $('.videoHolder');
  $holder.each(function () {
    let $that = $(this);
    let ratio = $that.data('ratio') ? $that.data('ratio') : '16:9';
    let width = parseFloat(ratio.split(':')[0]);
    let height = parseFloat(ratio.split(':')[1]);
    $that.find('.video').each(function () {
      if ($that.width() / width > $that.height() / height) {
        $(this).css({
          width: '100%',
          height: 'auto',
        });
      } else {
        $(this).css({
          width: ($that.height() * width) / height,
          height: '100%',
        });
      }
    });
  });
}

/**
 * Megamenu
 */

/* eslint-disable */
function replaceDataName() {
  $('.megamenu-wrapper').each(function () {
    let newDataName = $(this).data('name').toLowerCase().replace(/\s+/g, '_');
    $(this).attr('data-name', newDataName);
  });
  $('.megamenu-item-wrapper').each(function () {
    let newDataName = $(this).data('name').toLowerCase().replace(/\s+/g, '_');
    $(this).attr('data-name', newDataName);
  });
}

function megaMenuShow() {
  const megaMenu = $('.megamenu-item');
  if (megaMenu.length === 0) return;
  const megaWrapper = $('.megamenu-wrapper');

  megaWrapper.on('mouseenter', function () {
    if ($(window).innerWidth() >= 1092) {
      $('.megamenu-item-wrapper').css('display', 'none');
      megaWrapper.removeClass('is-active');
      // megaMenu.fadeOut();
      // megaMenu.css('display', 'none');
      $(this).find(megaMenu).fadeIn(300);
      $(this).addClass('is-active');
      const wrapperName = $(this).data('name');
      let items = $(this).find('.megamenu-item-wrapper');

      items.each(function () {
        if ($(this).data('name') === wrapperName) {
          $(this).css('display', 'block');
        }
      });
    }
  });

  megaWrapper.on('mouseleave', function () {
    if ($(window).innerWidth() >= 1092) {
      megaMenu.fadeOut(300);
      $(this).removeClass('is-active');
    }
  });

  $('.submenu-menu-toggle').on('click', function () {
    if ($(window).innerWidth() < 1092) {
      $('.megamenu-item-wrapper').css('display', 'none');
      $(this).parent().find(megaMenu).toggleClass('show');
      $(this).parent().toggleClass('is-active');
      const wrapperName = $(this).parent().data('name');
      let items = $(this).parent().find('.megamenu-item-wrapper');

      items.each(function () {
        if ($(this).data('name') === wrapperName) {
          $(this).toggleClass('show');
        }
      });
    }
  });

  $('.megamenu-item-wrapper').on('click', function (e) {
    e.stopPropagation();
  });
}
//Show Contact Us on menu
function show_contact_us() {
  $(document).on('click', 'a[href^="#contact_us_menu"]', function (event) {
    event.preventDefault();
    $('html, body').animate({ scrollTop: 0 }, 'slow');
    if ($(window).innerWidth() >= 1092) {
      $('.megamenu-wrapper[data-name=contact_us]').addClass('is-active');
      let id = $('.megamenu-wrapper[data-name=contact_us]').data('id');
      let item = $('.megamenu-wrapper[data-name=contact_us]').find(
        '.megamenu-item'
      );
      item.css('display', 'block');
      $('.megamenu-item-wrapper[data-name=contact_us]').css('display', 'block');
    }

    if ($(window).innerWidth() < 1092) {
      $('.megamenu-item-wrapper').removeClass('show');
      $('.top-bar').css('display', 'block');

      $('.megamenu-wrapper[data-name=contact_us]').addClass('is-active');

      $('.megamenu-wrapper[data-name=contact_us]')
        .find('.megamenu-item')
        .toggleClass('show');

      $('.megamenu-wrapper[data-name=contact_us]')
        .find('.megamenu-item .megamenu-item-wrapper[data-name=contact_us]')
        .toggleClass('show');

      // $('.megamenu-wrapper[data-name=contact_us]')
      //   .parent()
      //   .toggleClass('is-active');
      //
      // let menuItemWrapper = $(
      //   '.megamenu-item-wrapper[data-name=contact_us]'
      // ).addClass('show');
    }
    // $('.top-bar').css('display', 'block');
    // $('.megamenu-item-wrapper[data-name=contact_us]').css('display', 'block');
  });
}

//VIDEO
function youtubePlay() {
  const oembed = $('.video__oembed');
  if (!oembed.length) return;
  $('.video__image-wrapper').on('click', function () {
    const iframe = $(this).closest('.video__container').find('.oembed iframe');
    if (!iframe.length) return;
    iframe.attr(
      'src',
      iframe.attr('src').replace('?feature=oembed', '?autoplay=1')
    );

    $(this).fadeOut();
  });
}

function videoFilePlay() {
  $('.video__image-wrapper').on('click', function () {
    const video = $(this).closest('.video__container').find('.video');
    if (!video.length) return;
    video.trigger('play');
    $(this).fadeOut();
  });
}

function pauseTabsVideo() {
  const iframe = $('.tabs-video-slider ').find('.oembed iframe');
  const cover = $('.tabs-video-slider ').find('.video__image-wrapper');
  const videoFile = $('.tabs-video-slider ').find('.video');

  iframe.attr('src', iframe.attr('src').replace('?feature=oembed', '?'));

  videoFile.trigger('pause');
  cover.fadeIn();
}

function initSlickOnTabs() {
  const slider = $('.tabs-video-slider');
  //init first slider
  slider.each(function () {
    if ($(this).data('tab') === 1) {
      $(this).slick({
        infinite: true,
        rows: false,
        dots: true,
        arrows: false,
        autoplay: false,
        speed: 400,
        lazyLoad: 'ondemand',
      });
    }
  });

  //init slider on clicked tab
  $('.video-tabs__title a').on('click', function () {
    const tab = $(this).data('tab');

    slider.each(function () {
      if ($(this).data('tab') === tab) {
        if ($(this).hasClass('slick-initialized')) {
          return;
        } else {
          $(this).slick({
            infinite: true,
            rows: false,
            dots: true,
            arrows: false,
            autoplay: false,
            speed: 400,
            lazyLoad: 'ondemand',
          });
        }
      }
    });
  });
}

// Tabs dropdown
function tabsDropDown() {
  const windowWidth = $(window).width();
  if (windowWidth < 1200) {
    const tabsTitle = $('.video-library__title');
    const tabs = $('.video-tabs');
    if (!tabsTitle.length && !tabs.length) return;

    tabsTitle.on('click', function () {
      tabs.slideToggle('slow');
      tabsTitle.toggleClass('active');
      tabs.css('display', 'flex');
    });
    $('.video-tabs__title a').on('click', function () {
      tabs.slideToggle('slow');
      tabsTitle.toggleClass('active');
    });
  }
}

/**
 * Scripts which runs after DOM load
 */
$(document).on('ready', function () {
  youtubePlay();
  videoFilePlay();
  tabsDropDown();
  show_contact_us();

  // Select all links with hashes
  $('a[href*="#"]')
    // Remove links that don't actually link to anything
    .not('[href="#"]')
    .not('[href="#0"]')
    .click(function (event) {
      // On-page links
      if (
        location.pathname.replace(/^\//, '') ==
          this.pathname.replace(/^\//, '') &&
        location.hostname == this.hostname
      ) {
        // Figure out element to scroll to
        var target = $(this.hash);
        target = target.length
          ? target
          : $('[name=' + this.hash.slice(1) + ']');
        // Does a scroll target exist?
        if (target.length) {
          // Only prevent default if animation is actually gonna happen
          event.preventDefault();
          $('html, body').animate(
            {
              scrollTop: target.offset().top,
            },
            1000,
            function () {
              // Callback after animation
              // Must change focus!
              var $target = $(target);
              $target.focus();
              if ($target.is(':focus')) {
                // Checking if the target was focused
                return false;
              } else {
                $target.attr('tabindex', '-1'); // Adding tabindex for elements not focusable
                $target.focus(); // Set focus again
              }
            }
          );
        }
      }
    });

  $('.testimonials__slider').slick({
    infinite: true,
    fade: true,
    // cssEase: 'linear',
    rows: false,
    dots: false,
    arrows: true,
    autoplaySpeed: 5000,
    autoplay: true,
    speed: 700,
    lazyLoad: 'ondemand',
    // adaptiveHeight: true,
  });

  $('.full-width-slider').slick({
    infinite: true,
    rows: false,
    dots: false,
    arrows: true,
    autoplaySpeed: 5000,
    autoplay: true,
    speed: 700,
    lazyLoad: 'ondemand',
  });

  $('.video-tabs__title a').on('click', function () {
    pauseTabsVideo();
  });

  $('.tabs-video-slider').on(
    'beforeChange',
    function (event, { slideCount: count }, currentSlide, nextSlide) {
      pauseTabsVideo();
    }
  );

  initSlickOnTabs();

  $('.home-big-slider').slick({
    infinite: true,
    rows: false,
    dots: true,
    autoplay: true,
    autoplaySpeed: 6000,
    speed: 400,
    lazyLoad: 'ondemand',
  });

  /**
   * Megamenu
   */
  replaceDataName();
  megaMenuShow();

  /**
   * Make elements equal height
   */
  $('.matchHeight').matchHeight();

  /**
   * IE Object-fit cover polyfill
   */
  if ($('.of-cover').length) {
    objectFitImages('.of-cover');
  }

  /**
   * Add fancybox to images
   */
  // $('.gallery-item')
  //   .find('a[href$="jpg"], a[href$="png"], a[href$="gif"]')
  //   .attr('rel', 'gallery')
  //   .attr('data-fancybox', 'gallery');
  // $(
  //   '.fancybox, a[rel*="album"], a[href$="jpg"], a[href$="png"], a[href$="gif"]'
  // ).fancybox({
  //   minHeight: 0,
  //   helpers: {
  //     overlay: {
  //       locked: false,
  //     },
  //   },
  // });

  /**
   * Init parallax
   */
  // jarallaxElement();
  // jarallax(document.querySelectorAll('.jarallax'), {
  //   speed: 0.5,
  // });

  /**
   * Detect element appearance in viewport
   */
  // ScrollOut({
  //   offset: function() {
  //     return window.innerHeight - 200;
  //   },
  //   once: true,
  //   onShown: function(element) {
  //     if ($(element).is('.ease-order')) {
  //       $(element)
  //         .find('.ease-order__item')
  //         .each(function(i) {
  //           let $this = $(this);
  //           $(this).attr('data-scroll', '');
  //           window.setTimeout(function() {
  //             $this.attr('data-scroll', 'in');
  //           }, 300 * i);
  //         });
  //     }
  //   },
  // });

  /**
   * Remove placeholder on click
   */
  const removeFieldPlaceholder = () => {
    $('input, textarea').each((i, el) => {
      $(el)
        .data('holder', $(el).attr('placeholder'))
        .on('focusin', () => {
          $(el).attr('placeholder', '');
        })
        .on('focusout', () => {
          $(el).attr('placeholder', $(el).data('holder'));
        });
    });
  };

  removeFieldPlaceholder();

  $(document).on('gform_post_render', () => {
    removeFieldPlaceholder();
  });

  /**
   * Scroll to Gravity Form confirmation message after form submit
   */
  $(document).on('gform_confirmation_loaded', function (event, formId) {
    let $target = $('#gform_confirmation_wrapper_' + formId);
    if ($target.length) {
      $('html, body').animate({ scrollTop: $target.offset().top - 50 }, 500);
      return false;
    }
  });

  /**
   * Hide gravity forms required field message on data input
   */
  $('body').on(
    'change keyup',
    '.gfield input, .gfield textarea, .gfield select',
    function () {
      let $field = $(this).closest('.gfield');
      if ($field.hasClass('gfield_error') && $(this).val().length) {
        $field.find('.validation_message').hide();
      } else if ($field.hasClass('gfield_error') && !$(this).val().length) {
        $field.find('.validation_message').show();
      }
    }
  );

  /**
   * Add `is-active` class to menu-icon button on Responsive menu toggle
   * And remove it on breakpoint change
   */
  $(window)
    .on('toggled.zf.responsiveToggle', function () {
      $('.menu-icon').toggleClass('is-active');
    })
    .on('changed.zf.mediaquery', function () {
      $('.menu-icon').removeClass('is-active');
    });

  /**
   * Close responsive menu on orientation change
   */
  $(window).on('orientationchange', function () {
    setTimeout(function () {
      if ($('.menu-icon').hasClass('is-active') && window.innerWidth < 641) {
        $('[data-responsive-toggle="main-menu"]').foundation('toggleMenu');
      }
    }, 200);
  });

  resizeVideo();
});

/**
 * Scripts which runs after all elements load
 */
$(window).on('load', function () {
  // jQuery code goes here

  let $preloader = $('.preloader');
  if ($preloader.length) {
    $preloader.addClass('preloader--hidden');
  }
});

/**
 * Scripts which runs at window resize
 */
$(window).on('resize', function () {
  // jQuery code goes here
  // megaMenuShow();
  resizeVideo();
});

/**
 * Scripts which runs on scrolling
 */
$(window).on('scroll', function () {
  // jQuery code goes here
});
